import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PartnerCard from './Card';

class Partner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { specialties: '' };
  }

  filter() {
    this.setState((prevState) => { specialties: !prevState.showMenu })
  }
}

const Partners = () => {
  const { allPartnersJson } = useStaticQuery(query);
  const partners = allPartnersJson.edges.map(partner => partner.node);

  return (
    <>
      <div className="container pb-5 pt-md-7 pb-md-7">
        <div id="partners" className="row justify-content-center">
          <div className="card-deck">
            {partners.map(partner => (
              <div key={ partner.id } className="card">
                <PartnerCard card={partner} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const query = graphql`
  query {
    allPartnersJson(filter: {active: {eq: true}}, sort: {fields: header}) {
      edges {
        node {
          id
          header
          type
          link
          image
          description
        }
      }
    }
  }
`;

export default Partners;
