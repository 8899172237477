import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Partners from '../../components/partners';
import styled from 'styled-components';
import Link from '../../components/Link';

const CTA = styled(Link)`
  &&& {
    padding: 10px;
    margin: 10px;
    background: #4FB5B2;
    color: white;

    &:hover {
      background: #4fb5b2;
    }
  }
`;
const activeLink = {
  color: 'white',
  background: '#4fb5b2',
  paddingBottog: '20',
};

const Index = () => (
  <Layout bodyClass="page-partners">
    <SEO title="Partners" />
    <div className="intro mb-10">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Partners</h1>
            <p>
              BAASIS LABS partnered with the best enterpreneurs from different companies to provide our startups with the best possible mentoring across all aread needed to build a successful company.
            </p>
          </div>
        </div>
      </div>
    </div>

    <Partners />

    <div className="col-12 text-center mb-10">
      <CTA
        to="mailto:daniel.ong@mbanq.com"
        activeStyle={activeLink}
      >
        BECOME A PARTNER
      </CTA>
    </div>
  </Layout>
);

export default Index;
